/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "./src/styles/main.css"

export const shouldUpdateScroll = props => {
    const { routerProps } = props
    if (routerProps?.location?.pathname?.includes("our-story")) return false
    return true
}
